import { Permission } from "~/graphql/__generated__/types";

export type RoleName = "Admin" | "HC" | "staff" | "staff-fungsi" | "pimpinan";
export type Actions =
  | "canAccess"
  | "canAccessSelfData"
  | "canBulk"
  | "canCreate"
  | "canDelete"
  | "canRead"
  | "canUpdate";
export type AbilityObject = {
  ability: Actions[];
  module: string;
};

function mapPermissionInotAbility(permissions: Permission[]) {
  const abilityObject: AbilityObject[] = [];

  for (const permission of permissions) {
    const ability: AbilityObject = {
      ability: [],
      module: permission.module?.path as string,
    };
    Object.keys(permission).forEach((key) => {
      if (key.startsWith("can")) {
        if (permission[key as keyof Permission]) {
          ability.ability.push(key as Actions);
        }
      }
    });

    abilityObject.push(ability);
  }

  return abilityObject;
}

export function defineAbilitiesFor<TPermissions>({
  permissions,
}: {
  permissions: TPermissions;
}): AbilityObject[] {
  if (!permissions) {
    return [];
  }
  const abilityObject = mapPermissionInotAbility(permissions as Permission[]);

  return abilityObject;
}
