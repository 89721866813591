import { useAtom } from "jotai";

import { modalDeleteGlobalAtom } from "~/stores";
import ModalConfirmDelete from "./modal-confirm-delete";
import { useEffect } from "react";

function ModalDeleteGlobal() {
  const [modal, setModal] = useAtom(modalDeleteGlobalAtom);

  const { onClose: excClose, onConfirm: excConfirm } = modal;

  const onClose = () => {
    if (typeof excClose === "function") excClose();
    setModal((prev) => ({ ...prev, isOpen: false }));
  };
  const onConfirm = () => {
    excConfirm();
    setModal((prev) => ({ ...prev, isOpen: false }));
  };
  useEffect(() => {
    console.log(modal);
  }, [modal]);

  return <ModalConfirmDelete {...{ ...modal, onClose, onConfirm }} />;
}

export default ModalDeleteGlobal;
