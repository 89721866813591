import { ReactNode, useMemo } from "react";

import { AbilityContext } from "./ability-context";
import { defineAbilitiesFor } from "./config";

function AbilityGuard<TPermissions>({
  permissions,
  children,
}: {
  children: ReactNode;
  permissions: TPermissions;
}) {
  const ability = useMemo(() => {
    const userAbilty = defineAbilitiesFor({
      permissions,
    });

    return userAbilty;
  }, [permissions]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
}

export default AbilityGuard;
